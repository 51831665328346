import { setCookie } from 'nookies';

export const changeUnderscoreToSpace = (str: string) => {
    if (!str) return '';
    return str.replace(/_/g, ' ');
};

export const convertDateToTimestamp = (date: string = '') => date?.slice(0, 16);

export const setUserCookie = ({
    authToken,
    id,
    role,
}: {
    authToken: string;
    id: string;
    role: string;
}) => {
    setCookie(
        null,
        'user',
        JSON.stringify({
            authToken,
            id,
            role,
        }),
        {
            maxAge: 30 * 24 * 60 * 60,
            path: '/',
        },
    );
};
